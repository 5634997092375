import * as BoundaryService from "src/components/Boundary/services";
import {
  Box,
  Center,
  Divider,
  Flex,
  List,
  ListItem,
  Skeleton,
  Spacer,
  VStack,
} from "@chakra-ui/react";
import _ from "lodash";
import {
  FORM_SCHOOL_RANK_SUB_STATUS,
  FormSchoolRankSubStatusIndex,
} from "src/constants";
import useRequiredHasuraRoles from "src/hooks/useRequiredHasuraRoles";
import { SingleMoreMenu } from "src/scenes/orgAdmin/forms/components/SingleMoreMenu";
import * as GQL from "src/types/graphql";
import { HasuraRole } from "src/types/hasuraRole";
import * as RD from "src/types/remoteData";
import { SchoolRankWithTags } from "src/types/schoolRank";
import { StatusTag } from "../DataDisplay/StatusTag";
import { SchoolWithBoundaryTags } from "../Inputs/MultiSelectSchoolRank";
import { SchoolListItem } from "../Inputs/MultiSelectSchoolRank/SchoolListItem";
import { WithRequiredHasuraRoles } from "../Permissions/WithRequiredHasuraRoles";
import { WithUserPermissions } from "../Permissions/WithUserPermissions";
import { TagGroups } from "../Tags/TagGroups/TagGroups";
import { useMemo } from "react";
import { RemoteDataView } from "../Layout/RemoteDataView";
import { GenericError } from "../Feedback/GenericError";

type Props = {
  formId: uuid;
  enrollmentPeriodId: uuid;
  schoolsRank: SchoolRankWithTags[];
  onRefetchSchoolsRank?: () => void;
  tagGroups?: GQL.GetSchoolsRankView_tag_group[];
  isOfferEnabled?: boolean;
  rankingEnabled: boolean;
  onOffersAccepted?: () => void;
};
export const SchoolRanking: React.FC<Props> = ({
  formId,
  enrollmentPeriodId,
  schoolsRank,
  onRefetchSchoolsRank,
  tagGroups,
  isOfferEnabled = true,
  rankingEnabled,
  onOffersAccepted,
}) => {
  const isAdmin = useRequiredHasuraRoles([
    HasuraRole.ADMIN,
    HasuraRole.DISTRICT_ADMIN,
    HasuraRole.ORG_ADMIN,
    HasuraRole.SCHOOL_ADMIN,
  ]);

  const { withBoundaryTags } = BoundaryService.useBoundaryTags<
    SchoolRankWithTags["school"]
  >({
    formId,
  });

  const rankedSchoolsWithBoundaryTagsRemoteData = useMemo(() => {
    const list = schoolsRank.map((rankedSchool) => {
      const rd = withBoundaryTags(rankedSchool.school);
      return rd.map((schoolWithBoundaryTags) => ({
        ...rankedSchool,
        school: schoolWithBoundaryTags,
      }));
    });
    return RD.combine(list);
  }, [schoolsRank, withBoundaryTags]);

  const renderSelectItem = (school: SchoolWithBoundaryTags) => (
    <SchoolListItem school={school} />
  );

  return (
    <List
      styleType={rankingEnabled || isAdmin ? "none" : "disc"}
      marginLeft="0"
      display="flex"
      flexDirection="column"
      gap={3}
      fontSize="sm"
      position="relative"
    >
      <RemoteDataView
        error={(error) => <GenericError error={error} />}
        loading={<Loading />}
        remoteData={rankedSchoolsWithBoundaryTagsRemoteData}
      >
        {(rankedSchoolsWithBoundaryTags) => (
          <>
            {_.sortBy(
              rankedSchoolsWithBoundaryTags,
              (schoolRank) => schoolRank.rank
            ).map((schoolRank, idx) => {
              const hasRank =
                rankingEnabled && typeof schoolRank.rank === "number";

              const selectedFormSchools = RD.success([
                {
                  form: {
                    id: schoolRank.form.id,
                    status: schoolRank.form.status,
                  },
                  school: { id: schoolRank.school.id },
                },
              ]);

              const showDivider =
                (rankingEnabled || isAdmin) && idx < schoolsRank.length - 1;

              let ml: number;
              if (rankingEnabled) {
                ml = 2;
              } else {
                // ranking disabled
                if (isAdmin) {
                  ml = 0;
                } else {
                  ml = 4;
                }
              }

              return (
                <ListItem
                  key={schoolRank.school.id}
                  gap={3}
                  alignItems="center"
                  flexWrap="wrap"
                  ml={ml}
                  mr={2}
                >
                  <VStack alignItems="initial" width="100%">
                    <Flex direction="row" gap="2">
                      {hasRank && (
                        <>
                          <Box as="span">{schoolRank.rank + 1}</Box>
                          <Center height="5">
                            <Divider orientation="vertical" />
                          </Center>
                        </>
                      )}
                      {renderSelectItem(schoolRank.school)}
                      {schoolRank.sub_status && (
                        <Box minWidth="120">
                          <StatusTag
                            status={
                              FORM_SCHOOL_RANK_SUB_STATUS[
                                schoolRank.sub_status as FormSchoolRankSubStatusIndex
                              ]
                            }
                            customLabel={getSubstatusLabel(schoolRank, isAdmin)}
                          />
                        </Box>
                      )}
                      <Spacer />
                      <Box>
                        <WithRequiredHasuraRoles
                          roles={[
                            HasuraRole.ADMIN,
                            HasuraRole.DISTRICT_ADMIN,
                            HasuraRole.ORG_ADMIN,
                            HasuraRole.SCHOOL_ADMIN,
                          ]}
                        >
                          <WithUserPermissions permissions={["form:update"]}>
                            {isOfferEnabled && (
                              <SingleMoreMenu
                                selectedFormSchools={selectedFormSchools}
                                onRefetch={onRefetchSchoolsRank}
                                onOffersAccepted={onOffersAccepted}
                              />
                            )}
                          </WithUserPermissions>
                        </WithRequiredHasuraRoles>
                      </Box>
                    </Flex>
                    <TagGroups
                      schoolRank={schoolRank}
                      tagGroups={tagGroups}
                      enrollmentPeriodId={enrollmentPeriodId}
                    />
                    {showDivider && <Divider orientation="horizontal" />}
                  </VStack>
                </ListItem>
              );
            })}
          </>
        )}
      </RemoteDataView>
    </List>
  );
};

// This is for adding extra information to the substatus tag, including waitlist
// position and offer programs.
const getSubstatusLabel = (
  schoolRank: SchoolRankWithTags,
  isAdmin: boolean
) => {
  if (
    isAdmin &&
    schoolRank.offers[0] &&
    "grade" in schoolRank.offers[0] &&
    schoolRank.offers[0]?.grade.program
  ) {
    return `${schoolRank.offers[0]?.status}, ${schoolRank.offers[0]?.grade.program.label}`;
  }
  const waitlistPosition = schoolRank.waitlists[0]?.waitlist_position?.position;
  if (
    !schoolRank.offers[0] &&
    schoolRank.waitlists[0]?.status === GQL.waitlist_status_enum.Waitlisted &&
    waitlistPosition
  ) {
    return `${GQL.waitlist_status_enum.Waitlisted}, #${waitlistPosition}`;
  }
  return undefined;
};

export const Loading = () => (
  <Flex width="100%" maxWidth="30rem" direction="column" padding="2rem" gap="6">
    <Flex border="px solid red" height="1rem" width="100%" paddingX="2rem">
      <Skeleton height="1rem" width="100%" borderRadius="md" />
    </Flex>
    <Flex direction="column" gap="3" alignItems="center">
      <Skeleton height="2rem" width="100%" borderRadius="md" />
      <Skeleton height="2rem" width="100%" borderRadius="md" />
      <Skeleton height="2rem" width="100%" borderRadius="md" />
    </Flex>
  </Flex>
);
