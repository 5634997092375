import { ColumnDefWithTooltip } from "src/components/Table/Table";
import type { GqlSchoolForm } from "../types";
import { ColumnId } from "./constants";

export function buildWaitlistPositionColumnDef(): ColumnDefWithTooltip<GqlSchoolForm> {
  return {
    id: ColumnId.WaitlistPosition,
    header: "position",
    tooltip: "Sort by waitlist position",
    accessorFn: (row) => row.waitlist_position,
  };
}
