import { Text } from "@chakra-ui/react";
import _ from "lodash";
import { ColumnDefWithTooltip } from "src/components/Table/Table";
import { ColumnId } from "./constants";

type Row = {
  form_school_rank: {
    lottery_order: number | null;
  } | null;
};
export function buildTiebreakerColumnDef<
  T extends Row
>(): ColumnDefWithTooltip<T> {
  return {
    accessorFn: (row) => row.form_school_rank?.lottery_order,
    id: ColumnId.Tiebreaker,
    header: "tiebreaker",
    tooltip: "Sort by tiebreaker",
    cell: (props) => {
      const tieBreaker = props.row.original.form_school_rank?.lottery_order;

      if (tieBreaker == null) {
        return (
          <Text variant="placeholder" textAlign="right">
            No value
          </Text>
        );
      }

      return <Text textAlign="right">{_.round(tieBreaker, 2)}</Text>;
    },
  };
}
