import { gql } from "@apollo/client";

export const GET_PERSON_RELATIONSHIPS = gql`
  query GetPersonRelationships($id: uuid!) {
    relationship_person(
      where: {
        _and: {
          active: { _eq: true }
          _or: [{ second_person: { _eq: $id } }, { first_person: { _eq: $id } }]
        }
      }
    ) {
      id
      first {
        id
        reference_id
        first_name
        middle_name
        last_name
        person_type
      }
      second {
        id
        reference_id
        first_name
        middle_name
        last_name
        person_type
      }
    }
  }
`;

export const GET_DOCUMENT_INFO = gql`
  query GetDocumentoInfo($document_id: uuid!) {
    document_metadata_by_pk(document_id: $document_id) {
      filename
      document_id
      document_type
      form_answer_id
    }
  }
`;

export const GET_FORM_ANSWER_ID = gql`
  query GetFormAnswerId($question_id: uuid!, $form_id: uuid!) {
    form_answer(
      where: {
        _and: { form_id: { _eq: $form_id }, question_id: { _eq: $question_id } }
      }
    ) {
      id
    }
  }
`;

export const GET_FORM_TEMPLATES_BY_ENROLLMENT_PERIOD = gql`
  query GetFormTemplatesByEnrollmentPeriod(
    $enrollment_period: uuid!
    $include_rules: Boolean = false
  ) {
    form_template(
      where: {
        enrollment_period_id: { _eq: $enrollment_period }
        deleted_at: { _is_null: true }
      }
      order_by: { created_at: asc }
    ) {
      id
      name
      description
      active
      lottery_offers_enabled
      offer_start_at
      locked
      form_template_rule @include(if: $include_rules) {
        rules {
          status_rule {
            next_form_template {
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_BASIC_FORM_TEMPLATES_BY_ENROLLMENT_PERIOD = gql`
  query GetBasicFormTemplatesByEnrollmentPeriod(
    $enrollment_period: uuid!
    $search: form_template_bool_exp = {}
  ) {
    form_template(
      where: {
        _and: [
          {
            enrollment_period_id: { _eq: $enrollment_period }
            deleted_at: { _is_null: true }
          }
          $search
        ]
      }
      order_by: { created_at: asc }
    ) {
      id
      name
      description
      active
      locked
    }
  }
`;

export const GET_FORM_ASSIGNMENTS_FOR_STUDENT_AND_TEMPLATE = gql`
  query GetFormAssignmentsForStudentAndTemplate(
    $applicant_id: uuid!
    $form_template_id: uuid!
  ) {
    assigned_form(
      where: {
        has_started: { _eq: false }
        deleted_at: { _is_null: true }
        person_id: { _eq: $applicant_id }
        form_template_id: { _eq: $form_template_id }
      }
    ) {
      id
      person_id
      form_template {
        id
      }
      previous_form_id
      previous_offer_id
      previous_waitlist_id
    }
  }
`;
