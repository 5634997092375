import { Text } from "@chakra-ui/react";
import { ColumnDefWithTooltip } from "src/components/Table/Table";
import { ColumnId } from "./constants";

type Row = {
  form_school_rank: { rank: number | null } | null;
};
export function buildSchoolRankColumnDef<
  T extends Row
>(): ColumnDefWithTooltip<T> {
  return {
    accessorFn: (row) => row.form_school_rank?.rank,
    id: ColumnId.SchoolRank,
    header: "rank",
    tooltip: "Sort by rank",
    cell: (props) =>
      typeof props.row.original.form_school_rank?.rank === "number" ? (
        props.row.original.form_school_rank?.rank + 1
      ) : (
        <Text variant="placeholder">No rank</Text>
      ),
  };
}
