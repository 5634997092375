import { Flex, Switch, Text, useToast } from "@chakra-ui/react";
import { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { GET_FORM_TEMPLATES_BY_ENROLLMENT_PERIOD } from "src/components/graphql/queries";
import { WithRequiredHasuraRoles } from "src/components/Permissions/WithRequiredHasuraRoles";
import { Glossary } from "src/components/Text/Glossary";
import { useConfirmationDialog } from "src/hooks/useConfirmationDialog";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import {
  FormTemplateContent,
  FormTemplateStatuses
} from "src/services/url/OrgAdmin";
import * as GQL from "src/types/graphql";
import { HasuraRole } from "src/types/hasuraRole";
import { FormTemplateDefaultCard } from "./components/FormTemplateDefaultCard";
import { FormTemplateSummary } from "./components/FormTemplateSummary";
import { UPDATE_FORM_TEMPLATE_ACTIVE_STATUS } from "./graphql/mutations";
import { useFormBuilderFeature } from "./hooks/useFormBuilderFeature";

interface Props {
  formTemplate: GQL.GetFormTemplatesByEnrollmentPeriod_form_template;
}

export const FormTemplateCard: React.FC<Props> = ({ formTemplate }) => {
  const organization = useOrganization();
  const { isFormBuilderReadOnly, isFormBuilderFullEdit } =
    useFormBuilderFeature();

  const { enrollmentPeriodId = "" } = useParams();
  const toast = useToast();
  const [updateFormTemplateActiveStatus] = useRemoteDataMutation<
    GQL.UpdateFormTemplateActiveStatus,
    GQL.UpdateFormTemplateActiveStatusVariables
  >(UPDATE_FORM_TEMPLATE_ACTIVE_STATUS, {
    refetchQueries: [GET_FORM_TEMPLATES_BY_ENROLLMENT_PERIOD]
  });

  const { confirm: confirmActiveChange, confirmationDialog: activateDialog } =
    useConfirmationDialog({
      header: "Activate form?",
      body: <FormTemplateSummary formTemplate={formTemplate} />,
      cancelButton: {
        label: "Cancel"
      },
      confirmButton: {
        label: "Activate form"
      },
      translate: true
    });

  const {
    confirm: confirmInactiveChange,
    confirmationDialog: inactivateDialog
  } = useConfirmationDialog({
    header: "Deactivate form?",
    body: (
      <Text>
        <Glossary>
          This will prevent students from starting a new form responses and move
          any existing ones they may have to their History page. It has no
          effect on the admin panel
        </Glossary>
      </Text>
    ),
    cancelButton: {
      label: "Cancel"
    },
    confirmButton: {
      label: "Deactivate form"
    },
    translate: true
  });

  const handleSubmit = useCallback(async () => {
    try {
      await updateFormTemplateActiveStatus({
        variables: {
          id: formTemplate.id,
          form_template_status: !formTemplate.active
        }
      });
      toast({
        title: "Success",
        description: `Form ${
          formTemplate.active ? "deactivated" : "activated"
        }`,
        status: "success",
        duration: 2000
      });
    } catch (e) {
      toast({
        title: "Something went wrong",
        description: "Failed to change form status",
        status: "error",
        duration: 2000
      });
    }
  }, [formTemplate, toast, updateFormTemplateActiveStatus]);

  const handleActiveChangeClick = useCallback(async () => {
    if (formTemplate.active) {
      if (!(await confirmInactiveChange())) {
        return;
      }
    } else {
      if (!(await confirmActiveChange())) {
        return;
      }
    }

    handleSubmit();
  }, [
    confirmActiveChange,
    handleSubmit,
    confirmInactiveChange,
    formTemplate.active
  ]);

  const editRouteBasedOnPermission = useMemo(
    () =>
      organization
        .map((org) =>
          isFormBuilderReadOnly || isFormBuilderFullEdit
            ? FormTemplateContent.edit(org, enrollmentPeriodId, formTemplate.id)
            : FormTemplateStatuses.edit(
                org,
                enrollmentPeriodId,
                formTemplate.id
              )
        )
        .withDefault("#"),
    [
      isFormBuilderReadOnly,
      isFormBuilderFullEdit,
      organization,
      enrollmentPeriodId,
      formTemplate.id
    ]
  );

  const rulesNextFormTemplates = useMemo(
    () =>
      formTemplate.form_template_rule?.rules.map((rule) => [
        rule.status_rule?.next_form_template.name
      ]),
    [formTemplate]
  );

  return (
    <>
      <FormTemplateDefaultCard
        title={formTemplate.name}
        description={formTemplate.description}
        footerInfo={
          <>
            {rulesNextFormTemplates?.length && (
              <Flex direction="column">
                <Text fontSize="sm" color="gray.700">
                  Flows into
                </Text>

                {rulesNextFormTemplates?.map((nextFormTemplate, index) => (
                  <Text key={index} color="blackAlpha.600">
                    {nextFormTemplate}
                  </Text>
                ))}
              </Flex>
            )}
          </>
        }
        actionSwitch={
          <Flex alignItems="center" gap={2}>
            <WithRequiredHasuraRoles
              roles={[HasuraRole.ADMIN, HasuraRole.ORG_ADMIN]}
            >
              <Switch
                name="active"
                isChecked={formTemplate.active}
                onChange={handleActiveChangeClick}
              />
            </WithRequiredHasuraRoles>

            <Text fontSize="sm" fontWeight="400">
              {formTemplate.active ? "Active" : "Inactive"}
            </Text>
          </Flex>
        }
        editRoute={editRouteBasedOnPermission}
      />
      {activateDialog}
      {inactivateDialog}
    </>
  );
};
