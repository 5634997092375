import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Flex,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { WithRequiredHasuraRoles } from "src/components/Permissions/WithRequiredHasuraRoles";
import { useFormBuilderFeature } from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/hooks/useFormBuilderFeature";
import * as Draft from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/types/draft";
import * as AF from "src/types/formTemplate";
import { HasuraRole } from "src/types/hasuraRole";
import { InternalOnlyTag } from "../QuestionForm/QuestionExtraInfo/InternalOnlyTag";
import { EditSectionButton } from "./EditSectionButton";
import { RemoveSectionButton } from "./RemoveSectionButton";

type Props = {
  original: AF.Section<AF.WithId>;
  draft: Draft.Section | undefined;
  isNewSection?: boolean;
};
export const SectionForm: React.FC<Props> = ({
  original,
  draft,
  isNewSection,
}) => {
  const { isFormBuilderFullEdit } = useFormBuilderFeature();

  const hasChange = isNewSection || Draft.hasSectionChanges(original, draft);
  const section = draft ?? Draft.fromOriginalSection(original);

  return (
    <Flex direction="column" gap="3">
      {hasChange ? (
        <Alert status="warning" rounded="md">
          <AlertIcon />
          <AlertDescription>
            This section has changes that haven't been published and are not
            visible to parents.
          </AlertDescription>
        </Alert>
      ) : null}

      <Flex direction="row" gap="3" alignItems="center">
        <Box>
          <InternalOnlyTag section={section} />
          <Text as="h1" fontSize="lg" fontWeight="400">
            {section.title}
            {!!section.key && (
              <>
                <Text as="span" color="blackAlpha.600" fontWeight="500">
                  {" | "}Section key:{" "}
                </Text>

                <Text as="span" color="blackAlpha.600" fontWeight="400">
                  {section.key}
                </Text>
              </>
            )}
          </Text>
        </Box>

        {isNewSection && <RemoveSectionButton sectionId={section.id} />}

        <WithRequiredHasuraRoles
          roles={[HasuraRole.ADMIN, HasuraRole.ORG_ADMIN]}
        >
          {isFormBuilderFullEdit && <EditSectionButton section={section} />}
        </WithRequiredHasuraRoles>
      </Flex>
    </Flex>
  );
};
