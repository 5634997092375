import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { RiLightbulbLine } from "react-icons/ri";

export type Props = {
  isOpen: boolean;
  header: string;
  body: string;
  onClose: () => void;
};

export const Hint: React.FC<Props> = ({ onClose, isOpen, body, header }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <>
          <ModalHeader>{header}</ModalHeader>
          <ModalBody>
            <Flex direction="column" gap="4">
              <Tag
                bg="purple.500"
                color="white"
                alignSelf="flex-start"
                size="sm"
              >
                <TagLeftIcon as={RiLightbulbLine} />
                <TagLabel>Tip</TagLabel>
              </Tag>
              <Text whiteSpace="pre-wrap">{body}</Text>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              Done
            </Button>
          </ModalFooter>
        </>
      </ModalContent>
    </Modal>
  );
};
