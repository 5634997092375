import { useFlags } from "flagsmith/react";
import { useMemo } from "react";
import useRequiredHasuraRoles from "src/hooks/useRequiredHasuraRoles";
import useUser from "src/hooks/useUser";
import { Status } from "src/types/authData";
import { HasuraRole } from "src/types/hasuraRole";

export function useFormBuilderFeature() {
  const user = useUser();
  const isAvelaAdmin = useRequiredHasuraRoles([HasuraRole.ADMIN]);
  const isOrgAdmin = useRequiredHasuraRoles([HasuraRole.ORG_ADMIN]);

  const flags = useFlags([
    "form-builder-read-only",
    "form-builder-orgadmin-edit"
  ]);

  const feature = useMemo(() => {
    return {
      isLoading: user.status === Status.LOADING,
      isFormBuilderFullEdit:
        isAvelaAdmin ||
        (isOrgAdmin && flags["form-builder-orgadmin-edit"].enabled),
      isFormBuilderReadOnly:
        isOrgAdmin && flags["form-builder-read-only"].enabled
    };
  }, [flags, isAvelaAdmin, isOrgAdmin, user.status]);

  return feature;
}
