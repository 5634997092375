import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import { $isListNode, ListNode } from "@lexical/list";
import { INSERT_HORIZONTAL_RULE_COMMAND } from "@lexical/react/LexicalHorizontalRuleNode";
import { $isHeadingNode } from "@lexical/rich-text";
import {
  $getSelectionStyleValueForProperty,
  $isParentElementRTL,
  $patchStyleText,
} from "@lexical/selection";
import {
  $findMatchingParent,
  $getNearestNodeOfType,
  mergeRegister,
} from "@lexical/utils";
import {
  $getSelection,
  $isElementNode,
  $isRangeSelection,
  $isRootOrShadowRoot,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  ElementFormatType,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  INDENT_CONTENT_COMMAND,
  LexicalEditor,
  OUTDENT_CONTENT_COMMAND,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  UNDO_COMMAND,
} from "lexical";
import {
  Dispatch,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import { IS_APPLE } from "../../utils/environment";

import { HStack } from "@chakra-ui/react";
import { BsType } from "react-icons/bs";
import {
  RiAddLine,
  RiAlignCenter,
  RiAlignJustify,
  RiAlignLeft,
  RiAlignRight,
  RiArrowGoBackLine,
  RiArrowGoForwardLine,
  RiBold,
  RiCheckboxLine,
  RiCodeLine,
  RiDoubleQuotesL,
  RiFormatClear,
  RiH1,
  RiH2,
  RiH3,
  RiH4,
  RiH5,
  RiH6,
  RiImageLine,
  RiIndentDecrease,
  RiIndentIncrease,
  RiItalic,
  RiLink,
  RiListOrdered,
  RiListUnordered,
  RiParagraph,
  RiSeparator,
  RiStrikethrough,
  RiSubscript,
  RiSuperscript,
  RiUnderline,
} from "react-icons/ri";
import {
  DropDown,
  DropDownItem,
  TbButton,
  TbDivider,
  TbToggle,
} from "../../components";
import {
  blockTypeToBlockName,
  useToolbarState,
} from "../../context/ToolbarContext";
import useModal from "../../hooks/useModal";
import { getSelectedNode } from "../../utils/getSelectedNode";
import { InsertImageDialog } from "../ImagesPlugin";
import { SHORTCUTS } from "../ShortcutsPlugin/shortcuts";
import FontSize from "./fontSize";
import {
  clearFormatting,
  formatBulletList,
  formatHeading,
  formatNumberedList,
  formatParagraph,
  formatQuote,
} from "./utils";
import { useSettings } from "../../context/SettingsContext";

const FONT_FAMILY_OPTIONS: [string, string][] = [
  ["Arial", "Arial"],
  ["Courier New", "Courier New"],
  ["Georgia", "Georgia"],
  ["Times New Roman", "Times New Roman"],
  ["Trebuchet MS", "Trebuchet MS"],
  ["Verdana", "Verdana"],
];

const FONT_SIZE_OPTIONS: [string, string][] = [
  ["10px", "10px"],
  ["11px", "11px"],
  ["12px", "12px"],
  ["13px", "13px"],
  ["14px", "14px"],
  ["15px", "15px"],
  ["16px", "16px"],
  ["17px", "17px"],
  ["18px", "18px"],
  ["19px", "19px"],
  ["20px", "20px"],
];

const ELEMENT_FORMAT_OPTIONS: {
  [key in Exclude<ElementFormatType, "">]: {
    icon: ReactElement;
    name: string;
  };
} = {
  center: {
    icon: <RiAlignCenter />,
    name: "Center Align",
  },
  end: {
    icon: <RiAlignRight />,
    name: "End Align",
  },
  justify: {
    icon: <RiAlignJustify />,
    name: "Justify Align",
  },
  left: {
    icon: <RiAlignLeft />,
    name: "Left Align",
  },
  right: {
    icon: <RiAlignRight />,
    name: "Right Align",
  },
  start: {
    icon: <RiAlignLeft />,
    name: "Start Align",
  },
};

const blockTypeIcons = {
  bullet: <RiListUnordered />,
  check: <RiCheckboxLine />,
  code: <RiCodeLine />,
  h1: <RiH1 />,
  h2: <RiH2 />,
  h3: <RiH3 />,
  h4: <RiH4 />,
  h5: <RiH5 />,
  h6: <RiH6 />,
  number: <RiListOrdered />,
  paragraph: <RiParagraph />,
  quote: <RiDoubleQuotesL />,
};

function BlockFormatDropDown({
  editor,
  blockType,
  disabled = false,
}: {
  blockType: keyof typeof blockTypeToBlockName;
  editor: LexicalEditor;
  disabled?: boolean;
}): JSX.Element {
  return (
    <DropDown
      isDisabled={disabled}
      icon={blockTypeIcons[blockType]}
      aria-label="Formatting options for text style"
    >
      <DropDownItem
        onClick={() => formatParagraph(editor)}
        shortcut={SHORTCUTS.NORMAL}
        icon={blockTypeIcons.paragraph}
        title="Normal"
      />
      <DropDownItem
        onClick={() => formatHeading(editor, blockType, "h1")}
        shortcut={SHORTCUTS.HEADING1}
        icon={blockTypeIcons.h1}
        title="Heading 1"
      />
      <DropDownItem
        onClick={() => formatHeading(editor, blockType, "h2")}
        shortcut={SHORTCUTS.HEADING2}
        icon={blockTypeIcons.h2}
        title="Heading 2"
      />
      <DropDownItem
        onClick={() => formatHeading(editor, blockType, "h3")}
        shortcut={SHORTCUTS.HEADING3}
        icon={blockTypeIcons.h3}
        title="Heading 3"
      />
      <DropDownItem
        onClick={() => formatBulletList(editor, blockType)}
        shortcut={SHORTCUTS.BULLET_LIST}
        icon={blockTypeIcons.bullet}
        title="Bullet List"
      />
      <DropDownItem
        onClick={() => formatNumberedList(editor, blockType)}
        shortcut={SHORTCUTS.NUMBERED_LIST}
        icon={blockTypeIcons.number}
        title="Numbered List"
      />
      {/* <DropDownItem
        onClick={() => formatCheckList(editor, blockType)}
        shortcut={SHORTCUTS.CHECK_LIST}
        icon={blockTypeIcons.check}
        title="Check List"
      /> */}
      <DropDownItem
        onClick={() => formatQuote(editor, blockType)}
        shortcut={SHORTCUTS.QUOTE}
        icon={blockTypeIcons.quote}
        title="Quote"
      />
    </DropDown>
  );
}

function FontDropDown({
  editor,
  value,
  styleType: style,
  disabled = false,
}: {
  editor: LexicalEditor;
  value: string;
  styleType: string;
  disabled?: boolean;
}): JSX.Element {
  const handleClick = useCallback(
    (option: string) => {
      editor.update(() => {
        const selection = $getSelection();
        if (selection !== null) {
          $patchStyleText(selection, {
            [style]: option,
          });
        }
      });
    },
    [editor, style]
  );

  const buttonAriaLabel =
    style === "font-family"
      ? "Formatting options for font family"
      : "Formatting options for font size";

  return (
    <DropDown isDisabled={disabled} label={value} aria-label={buttonAriaLabel}>
      {(style === "font-family" ? FONT_FAMILY_OPTIONS : FONT_SIZE_OPTIONS).map(
        ([option, text]) => (
          <DropDownItem
            onClick={() => handleClick(option)}
            key={option}
            title={text}
          />
        )
      )}
    </DropDown>
  );
}

function ElementFormatDropdown({
  editor,
  value,
  disabled = false,
}: {
  editor: LexicalEditor;
  value: ElementFormatType;
  disabled: boolean;
}) {
  const formatOption = ELEMENT_FORMAT_OPTIONS[value || "left"];

  return (
    <DropDown
      isDisabled={disabled}
      icon={formatOption.icon}
      aria-label="Formatting options for text alignment"
    >
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left");
        }}
        icon={ELEMENT_FORMAT_OPTIONS.left.icon}
        title="Left Align"
        shortcut={SHORTCUTS.LEFT_ALIGN}
      />
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center");
        }}
        icon={ELEMENT_FORMAT_OPTIONS.center.icon}
        title="Center Align"
        shortcut={SHORTCUTS.CENTER_ALIGN}
      />
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right");
        }}
        icon={ELEMENT_FORMAT_OPTIONS.right.icon}
        title="Right Align"
        shortcut={SHORTCUTS.RIGHT_ALIGN}
      />
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "justify");
        }}
        icon={ELEMENT_FORMAT_OPTIONS.justify.icon}
        title="Justify Align"
        shortcut={SHORTCUTS.JUSTIFY_ALIGN}
      />
      <TbDivider orientation="horizontal" />
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined);
        }}
        icon={<RiIndentDecrease />}
        title="Outdent"
        shortcut={SHORTCUTS.OUTDENT}
      />
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(INDENT_CONTENT_COMMAND, undefined);
        }}
        icon={<RiIndentIncrease />}
        title="Indent"
        shortcut={SHORTCUTS.INDENT}
      />
    </DropDown>
  );
}

export default function ToolbarPlugin({
  editor,
  activeEditor,
  setActiveEditor,
  setIsLinkEditMode,
}: {
  editor: LexicalEditor;
  activeEditor: LexicalEditor;
  setActiveEditor: Dispatch<LexicalEditor>;
  setIsLinkEditMode: Dispatch<boolean>;
}): JSX.Element {
  const {
    settings: { enableFontFamily, enableFontSize },
  } = useSettings();

  const [modal, showModal] = useModal();
  const [isEditable, setIsEditable] = useState(() => editor.isEditable());
  const { toolbarState, updateToolbarState } = useToolbarState();

  const $updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      let element =
        anchorNode.getKey() === "root"
          ? anchorNode
          : $findMatchingParent(anchorNode, (e) => {
              const parent = e.getParent();
              return parent !== null && $isRootOrShadowRoot(parent);
            });

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow();
      }

      const elementKey = element.getKey();
      const elementDOM = activeEditor.getElementByKey(elementKey);

      updateToolbarState("isRTL", $isParentElementRTL(selection));

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      const isLink = $isLinkNode(parent) || $isLinkNode(node);
      updateToolbarState("isLink", isLink);

      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<ListNode>(
            anchorNode,
            ListNode
          );
          const type = parentList
            ? parentList.getListType()
            : element.getListType();

          updateToolbarState("blockType", type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          if (type in blockTypeToBlockName) {
            updateToolbarState(
              "blockType",
              type as keyof typeof blockTypeToBlockName
            );
          }
        }
      }
      // Handle buttons
      updateToolbarState(
        "fontColor",
        $getSelectionStyleValueForProperty(selection, "color", "#000")
      );
      updateToolbarState(
        "bgColor",
        $getSelectionStyleValueForProperty(
          selection,
          "background-color",
          "#fff"
        )
      );
      updateToolbarState(
        "fontFamily",
        $getSelectionStyleValueForProperty(selection, "font-family", "Arial")
      );
      let matchingParent;
      if ($isLinkNode(parent)) {
        // If node is a link, we need to fetch the parent paragraph node to set format
        matchingParent = $findMatchingParent(
          node,
          (parentNode) => $isElementNode(parentNode) && !parentNode.isInline()
        );
      }

      // If matchingParent is a valid node, pass it's format type
      updateToolbarState(
        "elementFormat",
        $isElementNode(matchingParent)
          ? matchingParent.getFormatType()
          : $isElementNode(node)
          ? node.getFormatType()
          : parent?.getFormatType() || "left"
      );
    }
    if ($isRangeSelection(selection)) {
      // Update text format
      updateToolbarState("isBold", selection.hasFormat("bold"));
      updateToolbarState("isItalic", selection.hasFormat("italic"));
      updateToolbarState("isUnderline", selection.hasFormat("underline"));
      updateToolbarState(
        "isStrikethrough",
        selection.hasFormat("strikethrough")
      );
      updateToolbarState("isSubscript", selection.hasFormat("subscript"));
      updateToolbarState("isSuperscript", selection.hasFormat("superscript"));
      updateToolbarState("isCode", selection.hasFormat("code"));
      updateToolbarState(
        "fontSize",
        $getSelectionStyleValueForProperty(selection, "font-size", "15px")
      );
    }
  }, [activeEditor, updateToolbarState]);

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, newEditor) => {
        setActiveEditor(newEditor);
        $updateToolbar();
        return false;
      },
      COMMAND_PRIORITY_CRITICAL
    );
  }, [editor, $updateToolbar, setActiveEditor]);

  useEffect(() => {
    activeEditor.getEditorState().read(() => {
      $updateToolbar();
    });
  }, [activeEditor, $updateToolbar]);

  useEffect(() => {
    return mergeRegister(
      editor.registerEditableListener((editable) => {
        setIsEditable(editable);
      }),
      activeEditor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          $updateToolbar();
        });
      }),
      activeEditor.registerCommand<boolean>(
        CAN_UNDO_COMMAND,
        (payload) => {
          updateToolbarState("canUndo", payload);
          return false;
        },
        COMMAND_PRIORITY_CRITICAL
      ),
      activeEditor.registerCommand<boolean>(
        CAN_REDO_COMMAND,
        (payload) => {
          updateToolbarState("canRedo", payload);
          return false;
        },
        COMMAND_PRIORITY_CRITICAL
      )
    );
  }, [$updateToolbar, activeEditor, editor, updateToolbarState]);

  const insertLink = useCallback(() => {
    if (!toolbarState.isLink) {
      setIsLinkEditMode(true);
    } else {
      setIsLinkEditMode(false);
      activeEditor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [activeEditor, setIsLinkEditMode, toolbarState.isLink]);

  return (
    <HStack
      gap={1}
      borderBottom="1px solid"
      borderColor="gray.200"
      borderRadius="md"
      padding={1}
    >
      <TbButton
        isDisabled={!toolbarState.canUndo || !isEditable}
        onClick={() => {
          activeEditor.dispatchCommand(UNDO_COMMAND, undefined);
        }}
        title={IS_APPLE ? "Undo (⌘Z)" : "Undo (Ctrl+Z)"}
        aria-label="Undo"
      >
        <RiArrowGoBackLine />
      </TbButton>
      <TbButton
        isDisabled={!toolbarState.canRedo || !isEditable}
        onClick={() => {
          activeEditor.dispatchCommand(REDO_COMMAND, undefined);
        }}
        title={IS_APPLE ? "Redo (⇧⌘Z)" : "Redo (Ctrl+Y)"}
        aria-label="Redo"
      >
        <RiArrowGoForwardLine />
      </TbButton>
      <TbDivider />
      {toolbarState.blockType in blockTypeToBlockName &&
        activeEditor === editor && (
          <>
            <BlockFormatDropDown
              disabled={!isEditable}
              blockType={toolbarState.blockType}
              editor={activeEditor}
            />
            <TbDivider />
          </>
        )}
      {enableFontFamily && (
        <>
          <FontDropDown
            disabled={!isEditable}
            styleType={"font-family"}
            value={toolbarState.fontFamily}
            editor={activeEditor}
          />
          <TbDivider />
        </>
      )}
      {enableFontSize && (
        <>
          <FontSize
            selectionFontSize={toolbarState.fontSize.slice(0, -2)}
            editor={activeEditor}
            disabled={!isEditable}
          />
          <TbDivider />
        </>
      )}
      <TbToggle
        isDisabled={!isEditable}
        isActive={toolbarState.isBold}
        onClick={() => {
          activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
        }}
        title={`Bold (${SHORTCUTS.BOLD})`}
        aria-label={`Format text as bold. Shortcut: ${SHORTCUTS.BOLD}`}
      >
        <RiBold />
      </TbToggle>
      <TbToggle
        isDisabled={!isEditable}
        isActive={toolbarState.isItalic}
        onClick={() => {
          activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
        }}
        title={`Italic (${SHORTCUTS.ITALIC})`}
        aria-label={`Format text as italics. Shortcut: ${SHORTCUTS.ITALIC}`}
      >
        <RiItalic />
      </TbToggle>
      <TbToggle
        isDisabled={!isEditable}
        isActive={toolbarState.isUnderline}
        onClick={() => {
          activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
        }}
        title={`Underline (${SHORTCUTS.UNDERLINE})`}
        aria-label={`Format text to underlined. Shortcut: ${SHORTCUTS.UNDERLINE}`}
      >
        <RiUnderline />
      </TbToggle>
      <TbToggle
        isDisabled={!isEditable}
        isActive={toolbarState.isCode}
        onClick={() => {
          activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "code");
        }}
        title={`Insert code block (${SHORTCUTS.INSERT_CODE_BLOCK})`}
        aria-label="Insert code block"
      >
        <RiCodeLine />
      </TbToggle>
      <TbToggle
        isDisabled={!isEditable}
        isActive={toolbarState.isLink}
        onClick={insertLink}
        aria-label="Insert link"
        title={`Insert link (${SHORTCUTS.INSERT_LINK})`}
      >
        <RiLink />
      </TbToggle>
      <DropDown
        isDisabled={!isEditable}
        label=""
        aria-label="Formatting options for additional text styles"
        icon={<BsType />}
      >
        <DropDownItem
          isActive={toolbarState.isStrikethrough}
          onClick={() => {
            activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough");
          }}
          title="Strikethrough"
          aria-label="Format text with a strikethrough"
          icon={<RiStrikethrough />}
          shortcut={SHORTCUTS.STRIKETHROUGH}
        />
        <DropDownItem
          isActive={toolbarState.isSubscript}
          onClick={() => {
            activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "subscript");
          }}
          title="Subscript"
          aria-label="Format text with a subscript"
          icon={<RiSubscript />}
          shortcut={SHORTCUTS.SUBSCRIPT}
        />
        <DropDownItem
          isActive={toolbarState.isSuperscript}
          onClick={() => {
            activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "superscript");
          }}
          title="Superscript"
          aria-label="Format text with a superscript"
          icon={<RiSuperscript />}
          shortcut={SHORTCUTS.SUPERSCRIPT}
        />
        <DropDownItem
          isActive={false}
          onClick={() => clearFormatting(activeEditor)}
          title="Clear text formatting"
          aria-label="Clear all text formatting"
          icon={<RiFormatClear />}
          shortcut={SHORTCUTS.CLEAR_FORMATTING}
        />
      </DropDown>
      <TbDivider />
      <DropDown
        isDisabled={!isEditable}
        aria-label="Insert specialized editor node"
        icon={<RiAddLine />}
      >
        <DropDownItem
          onClick={() => {
            activeEditor.dispatchCommand(
              INSERT_HORIZONTAL_RULE_COMMAND,
              undefined
            );
          }}
          icon={<RiSeparator />}
          title="Horizontal Rule"
        />
        <DropDownItem
          onClick={() => {
            showModal("Insert Image", (onClose) => (
              <InsertImageDialog
                activeEditor={activeEditor}
                onClose={onClose}
              />
            ));
          }}
          icon={<RiImageLine />}
          title="Image"
        />
      </DropDown>
      <TbDivider />
      <ElementFormatDropdown
        disabled={!isEditable}
        value={toolbarState.elementFormat}
        editor={activeEditor}
      />
      {modal}
    </HStack>
  );
}
