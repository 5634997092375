import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  ListItem,
  UnorderedList,
} from "@chakra-ui/react";
import { useCombobox } from "downshift";
import React from "react";
import { RiAddCircleLine } from "react-icons/ri";

interface BaseType {
  [key: string]: any;
}

type MultiSelectOrderableListProps<T> = {
  items: readonly T[];
  selectedItems: readonly T[];
  keyAttr: string;
  searchableFields: string[];
  label?: string;
  button: { label: string; disabled?: boolean };
  renderListItem: (item: T) => React.ReactElement;
  renderSelectedItems: (items: readonly T[]) => React.ReactElement;
  onSelectedItemChange: (item: T) => void;
  onIsDropdownOpenChange?: (isOpen: boolean) => void;
};

export function MultiSelectOrderableList<T extends BaseType>({
  items,
  selectedItems,
  keyAttr,
  searchableFields,
  label,
  button,
  onSelectedItemChange,
  renderListItem,
  renderSelectedItems,
  onIsDropdownOpenChange,
}: MultiSelectOrderableListProps<T>) {
  const [dropdownItems, setDropdownItems] = React.useState<readonly T[]>(items);
  const [query, setQuery] = React.useState<string>();

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    onInputValueChange({ inputValue }) {
      if (inputValue) {
        setQuery(inputValue);
      }
    },
    items: [...dropdownItems],
    onSelectedItemChange: ({ selectedItem }) => {
      setQuery(undefined);
      if (!selectedItem) {
        return;
      }

      onSelectedItemChange(selectedItem);
    },
    onIsOpenChange: (changes) => {
      if (
        changes.isOpen !== undefined &&
        onIsDropdownOpenChange !== undefined
      ) {
        onIsDropdownOpenChange(changes.isOpen);
      }

      if (changes.isOpen === false) {
        setQuery(undefined);
      }
    },
    selectedItem: null,
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            highlightedIndex: state.highlightedIndex,
            inputValue: "", // don't add the item string as input value at selection.
          };
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            inputValue: "", // don't add the item string as input value at selection.
          };
        default:
          return changes;
      }
    },
  });

  const getItemsFilter = React.useCallback(
    (inputValue: string) => {
      return function itemsFilter(item: T) {
        const fieldIncludesInput = !!searchableFields.find((field) =>
          item[field]?.toLowerCase().includes(inputValue.toLowerCase())
        );
        return (
          !selectedItems.includes(item) && (fieldIncludesInput || !inputValue)
        );
      };
    },
    [selectedItems, searchableFields]
  );

  React.useEffect(() => {
    setDropdownItems(items.filter(getItemsFilter(query || "")));
  }, [query, getItemsFilter, items]);

  const addButton = (
    <Box border="2px dashed" borderColor="gray.300" borderRadius="lg">
      <Button
        {...getToggleButtonProps()}
        padding={3}
        type="button"
        variant="link"
        width="100%"
        display="flex"
        alignItems="center"
        color="gray.700"
        gap={2}
        autoFocus={true}
        tabIndex={0}
        isDisabled={button.disabled === undefined ? false : button.disabled}
      >
        <Icon as={RiAddCircleLine} />
        {button.label}
      </Button>
    </Box>
  );
  return (
    <>
      {label && <label {...getLabelProps()}>{label}</label>}
      <UnorderedList
        {...getMenuProps()}
        listStyleType="none"
        margin="0"
        bg="white"
        borderRadius="md"
      >
        <Box
          visibility={isOpen ? "visible" : "hidden"}
          height={isOpen ? "inherit" : "0px"}
        >
          <Box padding={4}>
            <InputGroup>
              <InputLeftElement children={<SearchIcon color="gray" />} />
              <Input autoFocus={true} {...getInputProps()} />
            </InputGroup>
          </Box>
          {isOpen &&
            dropdownItems.map((item, index) => (
              <ListItem
                {...getItemProps({ item, index })}
                key={item[keyAttr]}
                backgroundColor={
                  highlightedIndex === index ? "gray.200" : "white"
                }
                padding={3}
                cursor="pointer"
                borderColor="gray.300"
                borderStyle="solid"
                borderBottomWidth="1px"
                _last={{ borderBottomWidth: "0" }}
                fontSize="sm"
              >
                <Flex direction="column" height="100%">
                  {renderListItem(item)}
                </Flex>
              </ListItem>
            ))}
        </Box>
      </UnorderedList>
      <Flex
        direction="column"
        gap={3}
        visibility={isOpen ? "hidden" : "visible"}
      >
        <Flex
          padding={4}
          gap={3}
          bg="white"
          borderRadius="lg"
          direction="column"
        >
          {renderSelectedItems(selectedItems)}
          {selectedItems.length === 0 ? addButton : null}
        </Flex>
        {selectedItems.length > 0 ? addButton : null}
      </Flex>
    </>
  );
}
